<template src="./Home.html"></template>

<script>
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import ArrobaMedellinHeader from "@/components/ArrobaMedellinAppBar/ArrobaMedellinAppBar";

export default {
  name: "home",
  components: { ArrobaMedellinLogo, ArrobaMedellinHeader },
  data() {
    return {
      showProfileAnimation: false,
      showAcademicAnimation: false,
      showNetworkingAnimation: false,
      showIdiAnimation: false,
      showCommertialAnimation: false,
      showEventsAnimation: false,
      showEmploymentAnimation: false,
      userInfo: null,
    };
  },
  mounted() {
    this.redirectToAdmin();
  },
  methods: {
    redirectToAdmin() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "Administrator") {
        this.$router.push("/AdminPanelAcademic");
      }
      if (role === "AdministratorCall") {
        this.$router.push("/administradorConvocatorias");
      }
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    mousemove(e) {
      let mouseX = e.clientX;
      let mouseY = e.clientY;
      let homeMoove = document.querySelectorAll(".home");
      let movCamera = homeMoove[0];
      let img_x = mouseX - this.coords(movCamera).x;
      let img_y = mouseY - this.coords(movCamera).y;
      movCamera.style.transform = `translateY(-${img_y * 0}px) translateX(-${
        img_x / 0
      }px) translateZ(0px)`;
    },
    coords(el) {
      let coords = el.getBoundingClientRect();
      return {
        x: coords.left,
        y: coords.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./Home.scss"></style>
